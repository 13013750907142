import { Controller } from "stimulus"

export default class extends Controller {
  show(event) {
    event.preventDefault
    Array.from(document.querySelectorAll(".popover-content")).map(pc => pc.style.display = 'none');
    const eventId = event.currentTarget.id;
    const popoverContent = document.getElementById(eventId + 'PopoverContent');

    popoverContent.style.position= 'absolute'
    popoverContent.style.left = event.offsetX+'px';
    popoverContent.style.top = event.offsetY+'px';
    popoverContent.style.display = 'block';
  }
}
