// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import owlCarousel from "owl.carousel2";
// import "owl.carousel2/dist/assets/owl.carousel.css";

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))


const FusionCharts = require("src/fusioncharts/fusioncharts");

// Require charts from fusioncharts
const Charts = require("src/fusioncharts/fusioncharts.charts");

// Require maps from fusioncharts
const Maps = require("src/fusioncharts/fusioncharts.maps");
const World = require("src/fusioncharts/fusioncharts.worldwithcountries");

// Require theme from fusioncharts
var FusionTheme = require("src/fusioncharts/themes/fusioncharts.theme.fusion");

// Add charts and themes and export module as dependency
Charts(FusionCharts);
FusionTheme(FusionCharts);
Maps(FusionCharts);
World(FusionCharts);


FusionCharts.options.license({
   key: '1nH2bqC-13D2E6E1D3E3I3A5A2B3E3B4E4D4rmeD7E4jlbA-8uG1B6d1paB2E3C1F3H3A8C5D4F4A3E3C2A17A32B13B9tkeG4H3D3mA9C11A5e1cvA2I3A1ptkE2B2G2phhC2B2C8B3E4B2C3H2C3B10C6ogaD3D2TF2wqe1B2E2B2qlE1GC3C1xzkB4D1H4C6D4A2D3F4H2C1B3C1A4C5s==',
   creditLabel: false,
});


const getCsrfToken = () => {
  const metas = document.getElementsByTagName("meta");
  for (let meta of metas) {
    if (meta.getAttribute("name") === "csrf-token") {
      return meta.getAttribute("content");
    }
  }
  return "";
}

/*
function svgString(eventObj) {
    //console.log(document.getElementById(eventObj.sender.container.parentElement.id+'_png'));
    if (svg2pngWasm && document.getElementById(eventObj.sender.container.parentElement.id+'_png') && 
       eventObj && eventObj.sender && eventObj.sender.container 
       && eventObj.sender.container.children && eventObj.sender.container.children.length > 0) {
      //console.log(eventObj.sender.container.parentElement.id);
      //console.log(eventObj.sender.container.children[0].outerHTML.toString());
      //svg2pngWasm.initialize(fetch('./svg2png_wasm_bg.wasm', {headers: {"Content-Type": "application/wasm"},})).then(() => {
      svg2pngWasm.svg2png(eventObj.sender.container.children[0].outerHTML.toString(),).then((png) => {
         //console.log(png);
         //document.getElementById(eventObj.sender.container.parentElement.id+'_png').src = URL.createObjectURL(
         //    new Blob([png], { type: 'image/png' }),
         //);
         const formData = new FormData();
         formData.append("keypng", eventObj.sender.container.parentElement.id);
         formData.append("file_name", eventObj.sender.container.children[0].id);
         formData.append("binary", new Blob([png], { type: 'image/png' }));
         formData.append("content_type", "image/png");
         //console.log(formData.get("file_name"));

         fetch('/async_images/create', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-CSRF-Token': getCsrfToken()
            },
            body: formData,
         });

       }
      );
      //});
    }
}
*/

function compressSVGO(svgInput, settings) {
  const floatPrecision = Number(settings.floatPrecision);
  const transformPrecision = Number(settings.transformPrecision);
  const plugins = [];

  const { data, error } = optimize(svgInput, {
    floatPrecision: floatPrecision,
    js2svg: {
      indent: 2,
    },
  });

  if (error) throw new Error(error);
  return { data };
}

function svgZipString(eventObj) {
    if (document.getElementById(eventObj.sender.container.parentElement.id+'_zip') && 
       eventObj && eventObj.sender && eventObj.sender.container 
       && eventObj.sender.container.children && eventObj.sender.container.children.length > 0) {

       let intoZip = eventObj.sender.container.children[0].outerHTML.toString();
       const retSvgo =  compressSVGO(intoZip, { plugins: [], floatPrecision: 1, transformPrecision: 3 });
       if (retSvgo && retSvgo.data) {
          new Response(new Response(retSvgo.data).
          body.pipeThrough(new CompressionStream("gzip"))).arrayBuffer().then(
          (result) => {
            let zip = new Uint8Array(result);
            const formData = new FormData();
            formData.append("keypng", eventObj.sender.container.parentElement.id);
            formData.append("file_name", eventObj.sender.container.children[0].id);
            formData.append("binary", new Blob([zip], { type: 'application/zip' }));
            formData.append("content_type", "application/zip");
  
            fetch('/async_images/create', {
              method: 'POST',
              credentials: 'same-origin',
              headers: {
                  'X-CSRF-Token': getCsrfToken()
              },
              body: formData,
            });
           }
         );
       }
    }
}

$(document).ready(async function() {
  //if (svg2pngWasm) {
  //    await svg2pngWasm.initialize(fetch('/svg2png_wasm_bg.wasm', {headers: {"Content-Type": "application/wasm"},}));
  //}
  FusionCharts && FusionCharts.ready(function() {
      FusionCharts.addEventListener("rendered", svgZipString); 
  });

  $(".owl-carousel").owlCarousel({
    margin: 10,
    nav: true,
    navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
    responsive: {
      0: {
        items: 1
      },
      620: {
        items: 3
      }
    }
  });

});

$(window).on("unload", function() {
  FusionCharts.removeEventListener("rendered", svgString);
});


