import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["entries", "pagination"]

  change() {
    var url = new URL(document.location);

    if (url.searchParams.get('view') === null || url.searchParams.get('view') === 'cards') {
      url.searchParams.set('view', 'list');
    } else {
      url.searchParams.set('view', 'cards');
    }

    window.location.href = url;
  }

}
