import { Controller } from "stimulus"

export default class extends Controller {
  show(event) {
    event.preventDefault
    Array.from(document.querySelectorAll(".tab-content")).map(tc => tc.hidden = true)
    Array.from(document.querySelectorAll(".tab")).map(tc => tc.classList.remove('is-active'))
    var target = event.currentTarget

    target.parentElement.classList.add('is-active')
    document.getElementById(target.id + 'Content').hidden = false
  }

}
