import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'searchContainer', 'searchTrigger', 'menuContainer', 'menuTrigger' ]

  connect() {
    this.close_search()
    this.close_menu()
  }

  open_search() {
    // stop the background from being able to scroll.
    document.documentElement.classList.add('is-clipped')
    // open the sidebar
    this.searchTriggerTarget.classList.add('is-active')
    this.searchContainerTarget.classList.add('is-active')
  }

  open_menu() {
    // stop the background from being able to scroll.
    document.documentElement.classList.add('is-clipped')
    // open the sidebar
    this.menuTriggerTarget.classList.add('is-active')
    this.menuContainerTarget.classList.add('is-active')
  }

  close_search() {
    // allow the background to scroll again.
    document.documentElement.classList.remove('is-clipped')

    // close the sidebar
    this.searchTriggerTarget.classList.remove('is-active')
    this.searchContainerTarget.classList.remove('is-active')
  }

  close_menu() {
    // allow the background to scroll again.
    document.documentElement.classList.remove('is-clipped')

    // close the sidebar
    this.menuTriggerTarget.classList.remove('is-active')
    this.menuContainerTarget.classList.remove('is-active')
  }
}
