import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['wikiurl', 'description', 'image']

  autofill(event) {
    event.preventDefault

    let url = event.target.dataset.href
    if (url === '') {
      return
    }
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      data: new URLSearchParams({'wikiurl': this.wikiurlTarget.value}).toString(),
      success: (data) => {
        this.descriptionTarget.value = data.description
        this.imageTarget.value = data.image
      }
    })
  }
}