import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['container', 'field']

  signUp(event) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    event.preventDefault
    let url = event.target.dataset.href
    if (this.fieldTarget.value === '' || !re.test(this.fieldTarget.value)) {
      this.fieldTarget.classList.add('textbox-error')
      return
    }

    Rails.ajax({
      type: 'POST',
      url: url,
      data: new URLSearchParams({ 'email': this.fieldTarget.value}).toString(),
      dataType: 'json',
      success: (data) => {
        this.containerTarget.innerHTML = data.response
      }
    })
  }
}