import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item_ingredients", "template_ingredients", "add_item_steps", "template_steps", "recipe_ingrediants", "template_recipe_ingrediants"]

  add_association_ingredients(event) {
    event.preventDefault()
    var content = this.template_ingredientsTarget.innerHTML.replace(/TEMPLATE_INGREDIENTS/g, this.genHexString(32))
    this.add_item_ingredientsTarget.insertAdjacentHTML('beforeend', content)
  }

  remove_association_ingredients(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields-ingredients")
    item.parentNode.removeChild(item)
  }

  add_association_steps(event) {
    event.preventDefault()
    var content = this.template_stepsTarget.innerHTML.replace(/TEMPLATE_STEPS/g, this.genHexString(32))
    this.add_item_stepsTarget.insertAdjacentHTML('beforeend', content)
  }

  remove_association_steps(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields-steps")
    item.parentNode.style.display = "none";
  }

  add_ingrediants(event) {
    event.preventDefault()
    var content = this.template_recipe_ingrediantsTarget.innerHTML.replace(/TEMPLATE_RECIPE_INGREDIENTS/g, this.genHexString(32))
    this.recipe_ingrediantsTarget.insertAdjacentHTML('beforeend', content)
  }

  remove_ingrediants(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields-steps");
    let inputNodes = item.getElementsByTagName('INPUT');
    for(let i = 0; i < inputNodes.length; ++i) {
      let inputNode = inputNodes[i];
      if(inputNode.type === 'hidden') {
        inputNode.value = true;
      }
      if(inputNode.type === 'number') {
        inputNode.required = false;
      }
      if(inputNode.type === 'text') {
        inputNode.required = false;
      }
    }
    item.style.display = "none";
  }

  genHexString(len) {
    let output = '';
    for (let i = 0; i < len; ++i) {
        output += (Math.floor(Math.random() * 16)).toString(16);
    }
    return output;
  }
}