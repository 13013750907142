import { Controller } from "stimulus"
import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min.js';

export default class extends Controller {
  target

  initCarousel() {
    bulmaCarousel.attach('.carousel', {
      slidesToShow: 3,
      pagination: false,
      navigationKeys: false
    });
    const collection = document.getElementsByClassName('carousel-wrapper-inner');
    for (let i = 0; i < collection.length; i++) {
      collection[i].classList.remove('is-one-third');
    }
  }

  show() {
    document.getElementById("searchFields").classList.add('has-addons')
    document.getElementById("mainSearchButton").hidden = false

    document.getElementById("mobileSearchFields").classList.add('has-addons')
    document.getElementById("mobileSearchButton").hidden = false
  }

  hide() {
    const mobileInput = document.getElementById("search_query_mobile")
    const input = document.getElementById("search_query_main")
    if(input.value !== "" || mobileInput.value !== "") {
      return
    }
    document.getElementById("searchFields").classList.remove('has-addons')
    document.getElementById("mainSearchButton").hidden = true;

    document.getElementById("mobileSearchFields").classList.remove('has-addons')
    document.getElementById("mobileSearchButton").hidden = true;
  }
}
