import { Controller } from "stimulus"

export default class extends Controller {
  add_file_name() {
    const file_name = document.getElementById('upload_dishes').value
    document.getElementById("fileName").innerHTML = file_name.replace(/^.*(\\|\/|\:)/, '')
    document.getElementById("fileSubmit").disabled = false

  }

  submit_file() {
    document.getElementById("fileSubmit").style.display = "none"
    document.getElementById("fileProgress").style.display = "block"
  }
}
