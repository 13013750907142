import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  go(event) {
    event.preventDefault

    const href = event.currentTarget.dataset.href

    window.location = href
  }

}
