import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['container', 'field', 'user']

  submit(event) {
    event.preventDefault

    let url = event.target.dataset.href
    if (this.fieldTarget.value === '') {
      this.fieldTarget.classList.add('textbox-error')
      return
    }
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      data: new URLSearchParams({'comment_body': this.fieldTarget.value, 'user_id': this.userTarget.value}).toString(),
      success: (data) => {
        this.containerTarget.innerHTML = data.response
        event.target.disabled = true
      }
    })
  }
}